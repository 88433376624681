<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="900" scrollable>
      <v-card>
        <v-toolbar dark>
          <v-card-title>
            <span>Pending Invoice</span>
            <hr>
          </v-card-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="closeDialog">
              <i class="fa fa-close"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea outlined dense v-model="cart.school_pay_remarks" :label="cart.type=='approved' ? 'Reason to approved' : 'Reason for rejection' "></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              x-large text
              @click="closeDialog">
            Cancel
          </v-btn>
          <v-btn @click="proceedRequest" :loading="loading"
                 x-large dark>
           {{cart.type=='approved' ? 'Approve' :'Reject' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import cartService from "@/services/cart/cartService";
const cart=new cartService();
export default {
  data(){
    return{
      dialog:false,
      loading:false,
      cart:{
        id:'',
        school_pay_remarks:'',
        type:'',
      }

    }
  },
  methods:{
    closeDialog(){
      this.dialog=false;
      this.cart={};
    },
    openDialog(cartId,type){
      this.cart.id=cartId;
      this.dialog=true;
      this.cart.type=type;
    },
    proceedRequest(){

      this.$confirm({
        message: `Are you sure you want to mark this invoice as paid? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading=true;
            cart
                .changeSchoolPay({'status':this.cart.type,'school_pay_remarks':this.cart.school_pay_remarks},this.cart.id)
                .then((response) => {

                  if(this.cart.type=='approved'){
                    this.$snotify.success("Invoice marked as paid");
                  }
                  if(this.cart.type=='rejected'){
                    this.$snotify.success("Invoice and candidate enrolment cancelled");
                  }
                  this.closeDialog();
                  this.$emit('refresh');

                })
                .catch((err) => {
                    this.$snotify.error("Oops something went wrong");
                })
                .finally(() => {
                    this.loading=false;
                });
            }
        }
      });

    },
   /* rejectCart(){
      this.$confirm({
        message: `Are you sure you want to mark this invoice as unpaid and cancel all candidates? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.isLoading=true;
            cart
                .changeSchoolPay({'status':'rejected'},cartId)
                .then((response) => {
                  this.getAllPendingCart();
                  this.$snotify.success("Invoice and candidate enrolment cancelled");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                })
                .finally(() => {
                  this.isLoading=false;
                });
          }
        }
      });

    },*/

  }
}
</script>