<template>
    <v-app>
      <div class="row">
        <v-overlay :value="isDeleting">
          <v-progress-circular
              indeterminate
              color="dark"
          ></v-progress-circular>
        </v-overlay>
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4>SchoolPay invoices</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard </router-link>
                    \ SchoolPay invoices 
                  </div>
                </div>
                <!-- <div class="breadcrumb-right">
                  <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret right no-flip>
                      <template v-slot:button-content>
                        <i class="ki ki-bold-more-hor"></i>
                      </template>
  
                      <div class="navi navi-hover min-w-md-250px">
                        <b-dropdown-text tag="div" class="navi-item">
                          <a class="navi-link" @click="exportToCsv">
                                  <span class="navi-icon">
                                      <i class="fa fa-file-csv"></i>
                                  </span>
                            <span class="navi-text">Export (CSV)</span>
                          </a>
                        </b-dropdown-text>
                        <b-dropdown-text tag="div" class="navi-item">
                          <a class="navi-link" @click="exportToExcel">
                                  <span class="navi-icon">
                                      <i class="fa fa-file-excel"></i>
                                  </span>
                            <span class="navi-text">Export (excel)</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                    </b-dropdown>
                </div> -->
              </div>
            </div>
            <div class="card-body">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                    <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type == 'federal'">
                        <v-select  
                            outlined 
                            dense 
                            label="Score" 
                            :items="scores" 
                            @input="search.score_id = $event !== null ? $event : ''" 
                            item-text="name" 
                            clearable 
                            item-value="id" 
                            v-on:keyup.enter="searchSchoolPayInvoice" 
                            v-model="search.score_id">
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                        <v-text-field 
                            label="Search by invoice number" 
                            v-model="search.invoice_no" 
                            @input="search.invoice_no = $event !== null ? $event : ''" 
                            outlined 
                            clearable 
                            v-on:keyup.enter="searchSchoolPayInvoice" 
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                            label="Search by enroller name or email" 
                            v-model="search.enroller_info" 
                            @input="search.enroller_info = $event !== null ? $event : ''" 
                            outlined 
                            clearable 
                            v-on:keyup.enter="searchSchoolPayInvoice" 
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-autocomplete
                          v-model="search.candidate_info"
                          :search-input.sync="candidateSearch"
                          :items="candidates"
                          :loading="isLoading"
                          item-text="display_text"
                          item-value="id"
                          hide-selected
                          outlined
                          dense
                          clearable
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                <strong>Search by candidate name or enrolment key  </strong>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <template v-slot:label>
                          Search by candidate name or enrolment key <span class="text-danger"></span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6" md="2">
                        <v-select 
                            outlined 
                            dense 
                            label="Pending status" 
                            :items="status" 
                            item-text="text" 
                            item-value="value" 
                            v-on:keyup.enter="searchSchoolPayInvoice" 
                            v-model="search.payment_status"
                        ></v-select>
                    </v-col>
                  
                  <v-col cols="12" sm="6" class="text-right" :md="currentUser.access_type == 'federal' ? 12 : 2">
                    <v-btn
                        @click="searchSchoolPayInvoice"
                        class="btn btn-primary"
                        style="color: #fff;"
                        :loading="loading"
                    >
                      <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>
                </div>
              </div>

              <div class="table-responsive">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>
  
                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>
                <table class="table">
                  <thead>
                  <tr class="text-left">
                    <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Invoice no.</th>
                    <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Candidate</th>
                    <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Enroller</th>
                    <th class="px-3" style="max-width: 270px !important; white-space: pre-wrap;">Status</th>
                    <th class="pr-3 text-center">Options</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template>
                    <tr v-for="(item,index) in invoices">
                      <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                        <span  class="">
                          {{ item.invoice_id }}
                        </span>
                      </td>
                      <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                        <div class="mb-2" v-if="item.candidate_info" v-for="(candidate, idx) in item.candidate_info">
                          <div class="font-weight-bold">
                            {{ candidate.full_name }}
                          </div>
                          <div>
                            {{ candidate.exam_key }}
                          </div>
                        </div>
                      </td>
                      <td class="px-2" style="max-width: 270px !important; white-space: pre-wrap;">
                        <span class="">
                          {{ item.user_full_name}}
                        </span>
                        <div class="font-weight-bold">
                            {{ item.user_email }}
                        </div>
                      </td>
                      
                      <td class="px-2">
                        <span class="badge" :class="item.payment_status=='pending' ? 'badge-warning':'badge-danger'">
                          {{ item.payment_status ? item.payment_status_text : '-'}}
                        </span>
                      </td>
  
                      <td class="pr-0 text-center">
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="viewInvoice(item.user_id,item.id)" class="navi-link" v-if="item.can_view_invoice">
                                  <span class="navi-icon">
                                      <i class="fa fa-file-invoice"></i>
                                  </span>
                                  <span class="navi-text">View Invoice</span>
                                </a>
                                <a class="navi-link" v-if="!item.can_view_invoice">
                                  <span class="navi-icon">
                                      <i class="fa fa-file-invoice"></i>
                                  </span>
                                  <span class="navi-text"><strike>View Invoice</strike></span>
                                </a>
                              </b-dropdown-text>
                              
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click.prevent="sendReminder(item.id)" class="navi-link">
                                  <span class="navi-icon">
                                      <v-icon>fas fa-bell</v-icon>
                                  </span>
                                  <span class="navi-text">Send reminder</span>
  
                                </a>
                              </b-dropdown-text>
  
                            </div>
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                    <tr v-if="invoices.length == 0">
                      <td colspan="8" class="text-center">
                        No items added
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>
                <b-pagination
                    v-if="invoices.length > 0"
                    class="pull-right mt-7"
                    @input="getSchoolPayInvoice"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                    :disabled="loading"
                ></b-pagination>
              </div>
            
            </div>
          </div>
        </div>
      </div>
        <approved-or-rejected ref="approve-reject" @refresh="getSchoolPayInvoice"></approved-or-rejected>
    </v-app>
  </template>
  <script>
  import cartService from "@/services/cart/cartService";
  import AccountholderService from "@/services/user/accountholder/AccountholderService";
  import ApprovedOrRejected from "@/view/pages/view/school/pay/pending-invoice/ApprovedOrRejected";
  import ScoreService from "@/services/score/score/ScoreService";
  import CandidateService from "@/services/candidate/CandidateService";
  
  const accountHolder = new AccountholderService()
  const cart=new cartService();
  const score = new ScoreService();
  const candidate = new CandidateService();
  
  export default {
    components:{
      ApprovedOrRejected,
    },
    data(){
      return{
        search:{
          info:'',
          is_active:'',
          score_id:'',
          payment_status:'pending',
          invoice_no: '',
          candidate_info: '',
          enroller_info: ''
        },
        isDeleting: false,
        total: null,
        perPage: null,
        page: null,
        loading:false,
        invoices:[],
        status:[
          {value:'pending',text:'Pending'},
          {value:'completed',text:'Completed'},
        ],
        scores: [],
        total: null,
        title: '',
        perPage: null,
        page: null,
        candidates: [],
        isLoading: false,
        candidateSearch: null,
      }
    },
    methods:{
      searchSchoolPayInvoice(){
        this.page = 1;
        this.getSchoolPayInvoice();
      },
      getSchoolPayInvoice(){
        this.loading = true;
        
        cart
        .getSchoolPayInvoice(this.search,this.page)
            .then((response) => {
              this.invoices=response.data.data;
              this.page = response.data.meta.current_page;
              this.total = response.data.meta.total;
              this.perPage = response.data.meta.per_page;
            }).catch((err) => {
  
             })
            .finally(() => {
              this.loading = false;
            });
      },
      approvedCart(cartId){
        this.$refs['approve-reject'].openDialog(cartId,'approved');
      },
      rejectCart(cartId){
        this.$refs['approve-reject'].openDialog(cartId,'rejected');
  
      },
      viewInvoice(accountHolderId,cartId) {
        accountHolder
            .viewInvoice(accountHolderId, cartId);
      },
      getAllScore() {
          score
          .getScores(this.search)
          .then(response => {
              this.scores = response.data.scores;
          });
      },
    //   exportToCsv(){
    //     cart
    //     .exportInvoiceToCsv(this.search);
    //   },
    //   exportToExcel(){
    //     cart
    //     .exportInvoiceToExcel(this.search);
    //   }
        sendReminder(cartId){
            this.$confirm({
                message: `You’re about to send a payment reminder email to the Enroller for this invoice.`,
                button: {
                    no: "Cancel",
                    yes: "Continue",
                },
                callback: (confirm) => {
                    if (confirm) {
                        cart
                        .sendReminder(cartId)
                        .then((response) => {
                            this.getSchoolPayInvoice();
                            this.$snotify.success("Reminder email sent sucessfully.");
                        })
                        .catch((err) => {
                            this.$snotify.error("Something went wrong");
                        });
                    }
                }
            });
        }
    },
    watch: {
      candidateSearch (val) {
        if (this.isLoading) return;
        this.isLoading = true;
        if(val != null) {
          candidate
            .getAllCandidate(val)
            .then((response) => {
              response.data.candidates.map(user => {
                  let data = user;
                  data.display_text=user.full_name+' ('+user.exam_key+')';
                  this.candidates.push(data)
              })
            })
            .catch(err => {

            })
            .finally(() => {
              this.isLoading = false
            })
        }
        this.isLoading = false
      }
    },
    mounted() {
      this.getSchoolPayInvoice();
      this.getAllScore();
    },
    computed: {
        currentUser() {
          return this.$store.getters.currentUser;
        },
        appEnv() {
          return process.env.VUE_APP_ENV;
        },
      },
  }
  </script>
  